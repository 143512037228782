/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppRoutes
 */
import * as React from 'react';

export const APP_ROUTES = {
    MAIN_BASE: '/main',
    MIGRATION: '/migration',
    QUESTIONNAIRE: '/questionnaire'

};