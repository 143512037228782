/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: DataLoaders
 */
import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Backdrop, CircularProgress, createStyles, makeStyles, Theme} from '@material-ui/core';

// ======================
// FullScreenLoading
// ======================
interface FullScreenLoadingProps {
    loading?: boolean
}

const useFullScreenLoadingStyles = makeStyles((theme: Theme) =>
                                                  createStyles({
                                                                   backdrop: {
                                                                       zIndex: theme.zIndex.modal + 1,
                                                                       color : '#FFFFFF',
                                                                   },
                                                               }),
);

export const FullScreenLoading: React.FC<FullScreenLoadingProps> = observer(({loading, ...p}) => {
    const styles = useFullScreenLoadingStyles(p);
    return <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress variant={'indeterminate'} color={'inherit'}/>
    </Backdrop>;
});