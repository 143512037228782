/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: errors
 */

import {AxiosError} from 'axios';

export class APIError extends Error {
    status: number;
    private axiosError: AxiosError | null = null;
    statusText: string;

    constructor(message: string, status: number = null, statusText: string = null, axiosError: AxiosError | null = null) {
        super(message);
        this.axiosError = axiosError;
        this.status     = status;
        this.statusText = statusText;
    }
}