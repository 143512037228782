/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: ProgressService
 */
import {computed, observable} from 'mobx';
import uuid from 'uuid/v4';
import {DialogService} from '../dialog/DialogService';
export class ProgressService {
    private dialogService: DialogService;

    constructor(dialogService: DialogService) {
        this.dialogService = dialogService;
    }

    @observable private trackers = observable.array<ProgressTrackerState>([]);

    private removeTracker(id: string) {
        const t = this.trackers.find(t => t.id === id);
        if (t)
        {
            this.trackers.remove(t);
        }
    }

    async track<T>(promise: Promise<T>, message: string = null, alertError = true): Promise<T> {
        const id = uuid();

        this.trackers.push({
                               id, message,
                           });

        try
        {
            return await promise;
        }
        catch (e)
        {
            if (alertError)
            {
                Promise.resolve(this.dialogService.addAlertDialog('Error', e.toString()));
            }
            throw e;
        }
        finally
        {
            this.removeTracker(id);
        }
    }

    @computed get hasOutstandingTrackers() {
        return this.trackers.length > 0;
    }
}

export interface ProgressTrackerState {
    id: string;
    message?: string;
}