/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppStates
 */

import {observer, useLocalStore} from 'mobx-react-lite';
import React, {createContext, useContext} from 'react';
import {ServerAPI} from '../core/ServerAPI';
import {AppGlobalService} from './AppGlobalService';
import {ProgressService} from '../core/progress/ProgressService';
import {DialogService} from '../core/dialog/DialogService';
import {MigrationQuestionnaireService} from "../migration/MigrationQuestionnaireService";
import {DropzoneService} from "../core/dropzone/DropzoneService";


const initState = () => {
    const api                               = new ServerAPI();
    const appGlobalService                  = new AppGlobalService();
    const dialogService                     = new DialogService();
    const progressService                   = new ProgressService(dialogService);
    const dropzoneService                   = new DropzoneService(api, dialogService);
    const migrationQuestionnaireService     = new MigrationQuestionnaireService(api, dialogService, progressService, dropzoneService)
    return {
        appGlobalService, progressService, dialogService, migrationQuestionnaireService, dropzoneService
    };
};


export type AppServicesType = ReturnType<typeof initState>;
export const appStateContext = createContext<AppServicesType | null>(null);


export const useAppServices = () => {
    const services = useContext(appStateContext);
    if (!services)
    {
        throw new Error('not inside app services provider');
    }
    return services;
};


// ======================
// AppServicesProvider
// ======================
interface AppServicesProviderProps {
}

export const AppServicesProvider: React.FC<AppServicesProviderProps> = observer(({...p}) => {
    const store = useLocalStore(initState);
    return <appStateContext.Provider value={store}>
        {p.children}
    </appStateContext.Provider>;
});


