import {ServerAPI} from "../core/ServerAPI";
import {DialogService} from "../core/dialog/DialogService";
import {ProgressService} from "../core/progress/ProgressService";
import {DropzoneService} from "../core/dropzone/DropzoneService";
import {MIGRATION_QUESTIONNAIRE_DATA_TYPE} from "./migration_questionnaire_types";
import {observable, makeAutoObservable} from 'mobx';



export class MigrationQuestionnaireService {
    api: ServerAPI;
    dialogService: DialogService;
    progressService: ProgressService;
    dropzoneService: DropzoneService;

    constructor(api: ServerAPI, dialogService: DialogService, progressService: ProgressService, dropzoneService: DropzoneService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        this.dropzoneService = dropzoneService;
        makeAutoObservable(this);
    }

    openStates: Array<boolean> = [true];

    addOpenState(index: number){
        this.openStates[index] = true;
    }

    openAll(){
        this.openStates.forEach((s,i)=> {
            this.openStates[i] = true;
        })
    }

    get openStatesLength(){
       return this.openStates.length
    }

    deleteOpenState(index: number){
        this.openStates.splice(index, 1);
    }

    setOpen(index: number){
        this.openStates[index] = true;
    }

    setClosed(index: number){
        this.openStates[index] = false;

    }

    toggleOpenState(index: number){
        const currentState = this.openStates[index];
        this.openStates[index] = !currentState;
    }


    async submitQuestionnaire(data: MIGRATION_QUESTIONNAIRE_DATA_TYPE) {

        const confirmed = await this.dialogService.catchAndAlertError(this.api.POST('questionnaires/migration', data))

        if (confirmed){
            await this.dialogService.addAlertDialog('Success!', `Questionnaire has been submitted to 
        CirrusData Project Team successfully. Reference Number to the questionnaire is ${confirmed.questionnaire_ref}.  
        Please have this number recorded for future reference`)
        }

    }
}

export const MIGRATION_QUESTIONNAIRE_HINTS = {
    source_storage: {
        total_capacity: "Total addressable size of all LUNs being migrated in this SAN.",
        total_storage_involved: "Number of separate storage arrays in this SAN.",
        san_connectivity: "Connectivity type: iSCSI or FC. If iSCSI, include switch reports, last 3-5 days of host performance stats, last 3-5 days of storage array performance stats, and makes and models of all adaptors in hosts that are in scope.",
        entries: {
            make: "Enter the vendor of the storage.",
            model: "Enter the model of the storage.",
            targets: "The number of physical ports being used by the hosts to be migrated",
            credentials: "Will there be people able to access the storage management interface using proper username/password",
            storage_report_file: "If possible collect the LUN masking report for all LUNs to be migrated. This is commonly created using the storage management interface, either command line or GUI"
        }
    },
    source_fabric: {
        vendor: "Select the vendor of the switches. It must be either Brocade or Cisco, or other OEM brands based on these two.",
        model: "Select the model of the switch.",
        link_speed: "The FC link speed is typically 2, 4, 8, 16 Gb. This must be accurately identified.",
        vsan_enabled: "Determine is the virtual fabric option, or VSAN option in Cisco is properly licensed and enabled. This determines the migration configuration, and may decide if it is possible to perform migration.",
        credentials: "Will there be people able to access the switches secure shell interface with proper username/password. The link insertion process requires this information to perform the tasks.",
        port_zone: "Is port zoning used in the switches.",
        switch_report_file: "If possible collect the zone report for all zones involving the host initiators and the storage targets involving the entities to be migrated. This is commonly done with ssh to the switch then download the zone infomation.",
        fabric_restrictions: "If there is any switch port availability concerns or restrictions"
    },
    san_config: {
        host_type_description: "Additional information that should be taken into consideration regarding host types",
        blade_server_notes: "If any blade servers are involved describe the make, model, and how what percentage of the hosts being migrated are in these blades.",
        lpar_notes: "If any AIX involves LPAR, indicate how many LPARs in scope to migrate."
    },
    destination: {
        total_storage_involved: "How many destination storage will be migrating to.",
        entries: {
            model: "The model of this destination storage to be migrated to.",
            credentials: "Credentials to access the management of this storage will be needed for auto-allocation and auto-provisioning."
        }
    },
    project_time_frame: {
        expected_start_date: "The preferred starting date for actual migration to start",
        expected_complete_date: "The preferred date for actual migration to be completed",
        must_start_date: "The hard deadline for the migration to start",
        must_complete_date: "The hard deadline for the migration to be completed"
    },
    site_info: {
        location: {
            address: "Address of the deployment site",
            security_requirements: "What security credentials are required to enter the facility, e.g., driver’s license, or passport, or special authorization from specific officer from the company.",
            escort_required: "Indicate whether the engineer requires escort inside the facility.",
            installation_permission: "Indicate whether is permission granted to deliver and install the migration appliance/equipment in the facility."
        }
    },
    network: {
        local: {
            firewall_restrictions: "Internal firewall restrictions that may prevent communication between the migration appliances, or access to the appliance GUI, or access from the appliances to the FC switches and destination storage management etc.",
            firewall_rules_modifiable: "If there are internal firewall restrictions can these rules be readily adjusted for the required migration operation.",
            network_security_notes: "Describe any other potential security restrictions to be considered."
        },
        remote: "What remote access is available for performing remote operation, e.g., VPN, Zoom, Webex, others."
    },

}
