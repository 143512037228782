/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppView
 */
import * as React from 'react';
//import {} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {APP_ROUTES} from './AppRoutes';
import {Route, Redirect, Switch, Link, useRouteMatch} from 'react-router-dom';
import {useAppServices} from './AppStates';
import {MainFrameLayout} from '../layout/MainLayout';
import {AppBar, CssBaseline, Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {AppTheme, DarkModeTheme} from './AppTheme';
import {MigrationProjectQuestionnaireForm} from "../migration/MigrationQuestionnaireView";


interface AppViewProps {
}

export const AppView: React.FC<AppViewProps> = observer(({...p}) => {
    return <>
        <Switch>
            <Redirect from={'/'} to={`${APP_ROUTES.MAIN_BASE}${APP_ROUTES.MIGRATION}${APP_ROUTES.QUESTIONNAIRE}`} exact/>
            <MainFrameLayout>
                <Route path={APP_ROUTES.MAIN_BASE}>
                    <MainView/>
                </Route>
            </MainFrameLayout>
        </Switch>
    </>;
});

// ======================
// MainView
// ======================

interface MainViewProps{
}

export const MainView: React.FC<MainViewProps> = observer((props)=>{
    let { path } = useRouteMatch();
    return <>
        <Switch>
            <Route path={APP_ROUTES.MAIN_BASE} exact>
                    <p>Main</p>
            </Route>
            <Route path={`${APP_ROUTES.MAIN_BASE}${APP_ROUTES.MIGRATION}`}>
                <MigrationView/>
            </Route>
        </Switch>
        </>
})

// ======================
// MigrationView
// ======================

interface MigrationViewProps{
}

export const MigrationView: React.FC<MigrationViewProps> = observer((props)=>{

    let { path } = useRouteMatch();
    return <>
        <Switch>
            <Route path={APP_ROUTES.MIGRATION}>
                    <p>Migration</p>
            </Route>
            <Route path={`${path}${APP_ROUTES.QUESTIONNAIRE}`}>
                    <MigrationProjectQuestionnaireForm/>
            </Route>
        </Switch>
    </>
})