/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppBackgroundViews
 */

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {useAppServices} from './AppStates';
import {FullScreenLoading} from '../core/data/DataLoaders';
import {GlobalDialogViews} from '../core/dialog/GlobalDialogViews';

// ======================
// AppBackgroundViews
// ======================
interface AppBackgroundViewsProps {
}

export const AppBackgroundViews: React.FC<AppBackgroundViewsProps> = observer(({...p}) => {
    const {progressService} = useAppServices();
    return <>
        <FullScreenLoading loading={progressService.hasOutstandingTrackers}/>
        <GlobalDialogViews/>
    </>;
});