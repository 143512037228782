// ======================
// AppInitialization
// ======================
import {observer} from 'mobx-react-lite';
import {useHistory} from 'react-router';
import {useAppServices} from './AppStates';
import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {isDevelopment} from '../../common/utils/util';
import {MainFrameLayout} from "../layout/MainLayout";
import {FullScreenLoading} from "../../common/splash/SplashScreen";
import {LoadingScreen} from "../../common/splash/SplashScreen";
import {useLocation} from "react-router";
//import {SplashScreen} from './SplashScreen';

interface AppInitializationProps {
}

export const AppInitialization: React.FC<AppInitializationProps> = observer(({...p}) => {
    const history                         = useHistory();
    const { appGlobalService} = useAppServices();
    const currentPage                     = useLocation().pathname

    const init = useCallback(async function init() {
        appGlobalService.initialized = false;
        console.log(`Initializing App. Development: ${isDevelopment()}`);

        // give auth service the history
        //await authService.initAuthentication(history, currentPage);

        // do something here if needed, check if already logged in, etc.

        //sleep
        //await appGlobalService.sleep();

        appGlobalService.initialized = true;
    }, []);

    useEffect(() => {
        init();
    }, []);

    return <>
        {appGlobalService.initialized ? p.children : <FullScreenLoading/>}
    </>
});

