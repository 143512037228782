/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: FormComponents
 */
import * as React from 'react';
import {
    Box,
    Checkbox,
    CheckboxProps,
    Chip,
    InputAdornment,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    makeStyles,
    Theme,
    Tooltip,
    FormHelperText,
    SvgIcon,
    Input,
    FormControl,
    OutlinedInput,
    OutlinedTextFieldProps,
    SelectProps,
    OutlinedInputProps,
    IconButton,
    Dialog,
    Typography,
    LinearProgress,
    Divider,
    Card,
} from '@material-ui/core';
import {FieldProps, useField} from 'formik';
import {observer} from 'mobx-react-lite';
import {IoMdHelpCircleOutline, IoIosCloseCircle, FaFileAlt, GoCloudUpload} from "react-icons/all";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    DateTimePickerProps,
    DatePickerProps,
    DatePicker
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {AutoCompleteFieldProps} from "../../modules/core/autocomplete/AutocompleteField";
import {Autocomplete} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useAppServices} from "../../modules/app/AppStates";
import {DROPZONE_FILE_TYPE} from "../../modules/migration/migration_questionnaire_types";
import {DropzoneState} from "../../modules/core/dropzone/DropzoneService";
import {useMigrationQuestionnaireStyles} from "../../modules/migration/MigrationQuestionnaireView";
import Dropzone from 'react-dropzone';
import xbytes from 'xbytes';

const useFormStyles = makeStyles((t: Theme) => ({
    fieldContainer: {
        display: 'flex'
    }
}))


// ======================
// FormikTextField
// ======================
interface FormTextFieldProps {
    label: string,
    name: string,
    icon?: React.ReactElement,
    multiline?: boolean,
    rowsMax?: number,
    hint?: string
}

export const FormTextField: React.FC<FormTextFieldProps & Partial<OutlinedTextFieldProps> & Partial<FieldProps>> = observer((props) => {
    const [field, meta] = useField(props.name);
    const error = meta.touched ? meta.error : null;
    return (
        <Box display={'flex'} alignItems={'center'}>
            {props.hint &&
            <HelpButton hint={props.hint} label={props.label}/>
            }
            <TextField variant={'outlined'} fullWidth error={!!error} name={field.name} label={props.label}
                       helperText={error} {...props} {...field}
                       InputProps={props.InputProps ? props.InputProps : props.icon ? {
                           startAdornment: (
                               <InputAdornment position={"start"}>
                                   {props.icon}
                               </InputAdornment>
                           )
                       } : null}/>
        </Box>
    )
});


interface FormCheckboxItemProps {
    label: string;
    name: string;
    helperText?: React.ReactNode,
    hint?: string

}

export const FormCheckboxItem: React.FC<FormCheckboxItemProps & Partial<CheckboxProps> & Partial<FieldProps>> = observer(({label, helperText, ...p}) => {
    const [field, meta, helpers] = useField(p.name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;
    return <Box display={'flex'} alignItems={'center'}>
        {p.hint &&
        <HelpButton label={label} hint={p.hint}/>
        }
        <ListItem disableGutters alignItems={'center'} dense>
            <ListItemIcon>
                <Checkbox
                    name={field.name} {...p} {...field}
                    onChange={(e) => setValue(e.target.checked)}
                    value={value}
                />
            </ListItemIcon>
            <ListItemText primary={label} secondary={helperText}/>
        </ListItem>
    </Box>;
});

// ======================
// FormDatePicker
// ======================

interface FormDatePickerProps {
    label: string,
    name: string,
    hint?: string
    convertDateToValue?: (date:Date)=>any
}

export const FormDatePicker: React.FC<FormDatePickerProps & Partial<DatePickerProps>> = observer((props) => {
    const { convertDateToValue, label, name, hint } = props;
    const [field, meta, helpers] = useField(name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;
    const onChange = (date: any) =>{
        if (convertDateToValue){
            setValue(convertDateToValue(date))
        } else{
            setValue(date)
        }
    }

    return (
        <Box alignItems={'center'} display={'flex'}>
            {hint &&
            <HelpButton label={label} hint={hint}/>
            }
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker label={label} error={!!error} value={value} helperText={error}
                                    required={props.required}
                                    onChange={onChange}
                                    variant={'inline'} autoOk inputVariant="outlined" format={'MM/dd/yy'}
                                    fullWidth
                                    {...props}
                />
            </MuiPickersUtilsProvider>
        </Box>

    )
})

// ======================
// FormDateTimePicker
// ======================

interface FormDateTimePickerProps {
    label: string,
    name: string,
    getErrorValue?: () => boolean;
}

export const FormDateTimePicker: React.FC<FormDateTimePickerProps & Partial<DateTimePickerProps>> = observer((props) => {
    const [field, meta, helpers] = useField(props.name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue, setError} = helpers;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker label={props.label} error={props.getErrorValue ? props.getErrorValue() : !!error}
                                    value={value} helperText={error} onChange={(date) => {
                setValue(date)
            }}
                                    variant={'inline'} autoOk inputVariant="outlined"
                                    format={'MM/dd/yy HH:mm'} {...props}
            />
        </MuiPickersUtilsProvider>
    )
})

// ======================
// FormSelect
// ======================

export const useSelectStyles = makeStyles((theme: Theme) => ({
        field: {
            //margin: theme.spacing(1),
            minWidth: 200,

        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        label: {
            paddingBottom: theme.spacing(2)
        },
        chip: {
            marginLeft: theme.spacing(1),
        }
    }),
);

interface FormSelectProps {
    label: string,
    name: string,
    selectionList: Array<any>,
    multiple?: boolean
    renderMethod?: (array: Array<any>) => any;
    chipRenderMethod?: (array: Array<any>) => (selected: any) => any;
    chipArray?: Array<any>;
    hint?: string

}

export const FormSelect: React.FC<FormSelectProps & Partial<SelectProps> & Partial<OutlinedInputProps>> = observer((props) => {
    const [field, meta, helpers] = useField({name: props.name, multiple: !!props.multiple});
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;

    const styles = useSelectStyles(props);

    const renderChip = (selected: any) => {
        return <div>
            {(selected as string[]).map(value => (
                <Chip key={value} label={value} className={styles.chip}/>
            ))}
        </div>
    }

    const renderList = (list: Array<any>) => {
        return list.map(name => (
            <MenuItem key={name} value={name}>
                {name}
            </MenuItem>
        ))
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.multiple ? setValue(event.target.value) : setValue(event.target.value as string)
    };

    return (
        <Box display={'flex'} alignItems={'center'}>
            {props.hint &&
            <HelpButton label={props.label} hint={props.hint}/>
            }
            <FormControl fullWidth>
                <InputLabel id={'select-label'} variant={'outlined'} error={!!error}
                            required={props.required}>{props.label}</InputLabel>

                <Select multiple={props.multiple} labelId={'select-label'}
                        {...props}
                        className={styles.field}
                        value={value}
                        onChange={handleChange}
                        renderValue={!props.multiple ? selected => selected : !!props.chipRenderMethod ? props.chipRenderMethod(props.chipArray) : renderChip}
                        variant={'outlined'}
                        error={!!error}
                        input={<OutlinedInput labelWidth={props.label.length * 9}/>}
                >

                    {!!props.renderMethod ? props.renderMethod(props.selectionList) : renderList(props.selectionList)}
                </Select>
                <FormHelperText error>
                    {error}
                </FormHelperText>
            </FormControl>

        </Box>
    )
})

// ======================
// AutocompleteFormField
// ======================

interface AutocompleteFormFieldProps {
    name: string;
    filterOptions?: (options: Array<any>) => Array<any>;
}

export const AutocompleteFormField: React.FC<AutocompleteFormFieldProps & Partial<AutoCompleteFieldProps>> = observer((props) => {
    const [field, meta, helpers] = useField(props.name);
    const {value} = meta;
    const {setValue} = helpers;
    const {name, renderOption, getOptionFormat, autocompleteState, label, tableData, style, onChangeFunction, multiple, filterData, id, freeSolo, getOptionLabel, filterOptions, ...p} = props;

    const open = autocompleteState.open;
    const loading = open && autocompleteState.data.loading;

    const setAutoCompleteOpen = async (e: React.ChangeEvent<{}>) => {
        if (!open) {
            autocompleteState.open = true;
            if (!!autocompleteState.fetchDataFunction) {
                await autocompleteState.fetchDataFunction();
            }

        } else {
            autocompleteState.open = false;
            if (autocompleteState.data) {
                autocompleteState.data.resetData();
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<{}>, value: any) => {
        autocompleteState.inputValue = value;
    }

    const handleChange = (e: React.ChangeEvent<{}>, value: any) => {
        multiple ? setValue(value as any[]) : setValue(value as string)
    }

    const getOptions = () => {
        let options = [];
        if (autocompleteState.data.ready) {
            if (Array.isArray(autocompleteState.data.data)) {
                if (!getOptionFormat) {
                    options = autocompleteState.data.data
                } else {
                    options = autocompleteState.data.data.map(getOptionFormat)
                }
            } else {
                if (!getOptionFormat) {
                    options = autocompleteState.data.data.items
                } else {
                    options = autocompleteState.data.data.items.map(getOptionFormat)
                }
            }

        }

        if (!!filterOptions) {
            options = filterOptions(options)
        }

        return options;

    }

    return <Autocomplete id={id}
                         open={autocompleteState.open}
                         onOpen={setAutoCompleteOpen}
                         onClose={setAutoCompleteOpen}
                         options={getOptions()}
                         loading={loading}
                         autoHighlight
                         freeSolo={freeSolo}
                         getOptionLabel={getOptionLabel ? getOptionLabel : multiple ? data => data as any[] : data => data as string}
                         style={style}
                         disableOpenOnFocus
                         onInputChange={handleInputChange}
                         onChange={handleChange}
                         inputValue={autocompleteState.inputValue}
                         value={multiple ? value as any[] : value as string}
                         multiple={multiple}
                         renderOption={renderOption}
                         renderInput={p => (
                             <TextField {...p} label={label} fullWidth
                                        InputProps={{
                                            ...p.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                    {p.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}/>
                         )}

    />;
})

// ======================
// HelpButton
// ======================

interface HelpButtonProps {
    hint: string;
    label: string;
}

export const HelpButton: React.FC<HelpButtonProps> = observer((props) => {
    const {hint, label} = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClickClose = () => {
        setOpen(false)
    }
    return (
        <Box pr={1}>
            <IconButton onClick={handleClickOpen}>
                <SvgIcon htmlColor={'#888888'}>
                    <IoMdHelpCircleOutline/>
                </SvgIcon>
            </IconButton>
            <Dialog open={open} onClose={handleClickClose}>
                <Box pl={2} pr={2} pt={2} pb={4}>
                    <Box pb={1}>
                        <Typography variant={'h5'}>{label}</Typography>
                    </Box>
                    <Typography variant={'body1'}>
                        {hint}
                    </Typography>
                </Box>
            </Dialog>
        </Box>
    )
})

// ======================
// QDropzone
// ======================

interface QDropzoneProps {
    label: string,
    dropzoneId: string,
    hint?: string,
}

export const QDropzone: React.FC<QDropzoneProps> = observer((props) => {

    const styles = useMigrationQuestionnaireStyles(props);

    const {label, dropzoneId, hint} = props;

    const {dropzoneService} = useAppServices();

    React.useEffect(() => {
        dropzoneService.initDropzoneState(dropzoneId);

        return () => {
            dropzoneService.deInitDropzoneState(dropzoneId)
        }
    }, [])

    const dropzoneState = dropzoneService.getDropzoneState(dropzoneId)

    const [field, meta, helpers] = useField(dropzoneId);
    const error = meta.touched ? meta.error : null;


    const {setValue} = helpers;


    const handleDrop = async (acceptedFiles) => {
        dropzoneState.addFile(acceptedFiles);
        await dropzoneState.uploadFiles()
        if (dropzoneState.files.length > 1) {
            setValue(dropzoneState.files)
        } else {
            setValue(dropzoneState.files[0])
        }
    }

    const handleDelete = (file: DROPZONE_FILE_TYPE) => {
        dropzoneState.deleteFile(file);
        if (dropzoneState.files.length < 1) {
            setValue(null)
        } else {
            setValue(dropzoneState.files)
        }
    }

    if (!!dropzoneState) {
        return <Box>
            <Dropzone onDrop={handleDrop} multiple>
                {({getRootProps, getInputProps, acceptedFiles}) => {
                    return <Box width={'100%'}>

                        <Box width={'100%'} display={'flex'} alignItems={'center'}>
                            {hint &&
                            <HelpButton hint={hint} label={label}/>
                            }
                            <Box width={'100%'}>
                                <Card variant={'outlined'}  {...getRootProps()} className={styles.dropzone}>
                                    <Box display={'flex'} alignItems={'center'} p={2}>
                                        <input {...getInputProps()}/>
                                        <Box pr={2}>
                                            <SvgIcon>
                                                <GoCloudUpload/>
                                            </SvgIcon>
                                        </Box>
                                        <Box>
                                            <Typography variant={'body1'}>{label}</Typography>
                                            <Typography variant={'body1'} color={'textSecondary'}>Drop file(s) or click to
                                                select.</Typography>
                                        </Box>
                                    </Box>
                                    <Box width={'100%'}>
                                        {dropzoneState.files && dropzoneState.files.map(file => {
                                            return <DropzoneFile dropzoneState={dropzoneState} file={file}
                                                                 handleDelete={handleDelete}/>
                                        })}
                                    </Box>
                                </Card>

                            </Box>
                        </Box>
                    </Box>
                }}
            </Dropzone>
            <Box pl={2} color={'error.main'}>
                <Typography variant={'caption'} color={'inherit'}>{error}</Typography>
            </Box>

        </Box>
    }

})

// ======================
// DropzoneFile
// ======================

interface DropzoneFileProps {
    dropzoneState: DropzoneState;
    file: DROPZONE_FILE_TYPE,
    handleDelete: (file: DROPZONE_FILE_TYPE) => void;
}

export const DropzoneFile: React.FC<DropzoneFileProps> = observer((props) => {
    const {dropzoneState, file, handleDelete} = props;
    return (<>
            <Divider/>

            <Box p={2} display={'flex'} alignItems={'center'}>
                <SvgIcon>
                    <FaFileAlt/>
                </SvgIcon>
                <Box pl={2} flexGrow={1}>
                    <Typography variant={'body1'}>{file.name}</Typography>
                    <Typography variant={'body2'}>{`${xbytes(file.size)} (${file.type})`}</Typography>
                </Box>
                <Box>
                    <IconButton onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(file)
                    }}>
                        <SvgIcon>
                            <IoIosCloseCircle/>
                        </SvgIcon>
                    </IconButton>
                </Box>
            </Box>
            <Box pl={2} pb={2} pr={2} width={'100%'} display={'flex'} justifyContent={'flex-start'}>
                <Typography variant={'body2'}
                            color={'textSecondary'}>{xbytes(dropzoneState.uploadProgress.loaded)}/{xbytes(dropzoneState.uploadProgress.total)} ({dropzoneState.uploadProgress.percent}%)</Typography>
            </Box>
            <LinearProgress variant={'determinate'} value={dropzoneState.uploadProgress.percent}/>
        </>
    )
})