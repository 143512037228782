/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppGlobalService
 */
import {makeAutoObservable, observable} from 'mobx';



export class AppGlobalService {
    constructor(){
        makeAutoObservable(this)
    }
    initialized = false;

    isLightMode = true;

    toggleLightMode() {
        this.isLightMode = !this.isLightMode
    }

    async sleep() {
        await sleepMS(1000);
    }

}



export const sleepMS = (duration = 0) => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, duration);
    });
};