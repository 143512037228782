import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../app/AppStates";
import * as yup from "yup";
import {Formik, useField, FieldArray} from "formik";
import {
    Box,
    Button,
    Divider,
    makeStyles,
    Theme,
    Typography,
    Grid,
    InputAdornment,
    Card,
    SvgIcon,
    IconButton,
    LinearProgress,
    AppBar,
    Toolbar,
    Collapse
} from "@material-ui/core";
import {
    FormCheckboxItem,
    FormDatePicker,
    FormSelect,
    FormTextField, HelpButton, QDropzone
} from "../../common/form/FormComponents";

import {
    DROPZONE_FILE_TYPE, MIGRATION_QUESTIONNAIRE_DATA_CONTACT_PERSON_INFO,
    MIGRATION_QUESTIONNAIRE_DATA_TYPE, PROJECT_SITE_TYPE,

} from "./migration_questionnaire_types";
import {MIGRATION_QUESTIONNAIRE_HINTS, MigrationQuestionnaireService} from "./MigrationQuestionnaireService";
import format from "date-fns/format";
import Dropzone from 'react-dropzone';
import xbytes from 'xbytes';

import {
    GoCloudUpload,
    IoMdHelpCircleOutline,
    FaFileAlt,
    IoIosCloseCircle,
    IoMdClose,
    MdAdd,
    FaAngleDown,
    FaAngleUp, MdDelete
} from "react-icons/all";
import {DropzoneState} from "../core/dropzone/DropzoneService";

// ======================
// MigrationProjectForm
// ======================

interface MigrationProjectQuestionnaireFormProps {

}

export const useMigrationQuestionnaireStyles = makeStyles((t: Theme) => ({
    dialog: {
        padding: t.spacing(4),
    },
    chip: {
        marginLeft: t.spacing(1),
    },
    fields: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    fieldGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    submitButton: {
        display: 'flex',
        justifyContent: 'center'
    },
    dropzone: {
        outline: 'none',
    },
    deleteButton: {
        color: t.palette.error.main,
        borderColor: t.palette.error.main
    },
    collapsibleAppBar: {
        cursor: 'pointer',
        "&:hover": {
            background: `rgba(21, 101, 192, .8)`
        }
    }
}))

export const MigrationProjectQuestionnaireForm: React.FC<MigrationProjectQuestionnaireFormProps> = observer((props) => {

    const styles = useMigrationQuestionnaireStyles(props);
    const {migrationQuestionnaireService} = useAppServices();

    const initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE = {
        user: {
            name: '',
            email: '',
            phone: null,
        },
        source_storage: {
            total_storage_involved: 1,
            total_capacity: 0,
            san_connectivity: '',
            entries: [{
                make: '',
                model: '',
                targets: '',
                credentials: null,
                storage_report_file: null
            }],
            iscsi: {
                switch_report: null,
                host_performance_stats: null,
                array_performance_stats: null,
                adaptors: []
            }
        },
        source_fabric: {
            model: '',
            vendor: '',
            port_zone: null,
            link_speed: null,
            credentials: null,
            vsan_enabled: null,
            fabric_restrictions: null,
            switch_report_file: null
        },
        san_config: {
            cluster: {
                aix: null,
                esx: null,
                hpux: null,
                linux: null,
                solaris: null,
                windows: null,
            },
            host_type_description: null,
            total_hosts: {
                aix: 0,
                esx: 0,
                hpux: 0,
                linux: 0,
                solaris: 0,
                windows: 0,
            },
            lpar_notes: 0,
            san_boot: {
                aix: null,
                esx: null,
                hpux: null,
                linux: null,
                solaris: null,
                windows: null,
            },
            average_initiators: {
                aix: 0,
                esx: 0,
                hpux: 0,
                linux: 0,
                solaris: 0,
                windows: 0,
            },
            blade_server_notes: null
        },
        destination: {
            total_storage_involved: 1,
            entries: [{
                model: '',
                credentials: null
            }]
        },
        network: {
            local: {
                firewall_restrictions: null,
                firewall_rules_modifiable: null,
                network_security_notes: null
            },
            remote: {
                remote_access_availability: null
            }
        },
        project_time_frame: {
            must_start_date: format(new Date(), 'MM/dd/yyyy'),
            must_complete_date: format(new Date(), 'MM/dd/yyyy'),
            expected_start_date: format(new Date(), 'MM/dd/yyyy'),
            expected_complete_date: format(new Date(), 'MM/dd/yyyy')
        },
        site_info: {
            description: null,
            customer: {
                name: null,
                email: null,
                phone: null,
                company: ''
            },
            location: {
                source: {
                    address: null,
                    onsite_contact: {
                        name: null,
                        email: null,
                        phone: null
                    },
                    escort_required: null,
                    security_requirements: null,
                    installation_permission: null
                },
            }
        },
        additional_site_info: [],
        misc: {
        }
    }

    const siteInfoSchema = yup.object().shape({
        description: yup.string().nullable(),
        customer: yup.object().shape({
            name: yup.string().nullable(),
            email: yup.string().nullable(),
            phone: yup.string().nullable(),
            company: yup.string().required('Enter customer company.')
        }),
        location: yup.object().shape({
            source: yup.object().shape({
                address: yup.string().nullable(),
                onsite_contact: yup.object().shape({
                    name: yup.string().nullable(),
                    email: yup.string().nullable(),
                    phone: yup.string().nullable()
                }),
                escort_required: yup.boolean().nullable(),
                security_requirements: yup.string().nullable(),
                installation_permission: yup.string().nullable()
            })
        })
    })

    const additionalSiteSchema = yup.object().shape({
        source_storage: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of source storage.'),
            total_capacity: yup.number().required('Enter total source storage capacity in TiB.'),
            san_connectivity: yup.string().required('Enter connectivity type.'),
            iscsi: yup.object().nullable().shape({
                switch_report: yup.mixed().required('Add a switch report.'),
                array_performance_stats: yup.mixed().required('Add array performance stats.'),
                host_performance_stats: yup.mixed().required('Add host performance stats.'),
                adaptors: yup.array().of(
                    yup.object().shape({
                        make: yup.string().required('Enter make.'),
                        model: yup.string().required('Enter model.')
                    })
                )
            }),
            entries: yup.array().of(
                yup.object().shape({
                    make: yup.string().required('Enter source storage make.'),
                    model: yup.string().required('Enter source storage model.'),
                    targets: yup.string().required('Enter number of targets.'),
                    credentials: yup.boolean().nullable(),
                    source_storage_report: yup.mixed().nullable()
                })
            )
        }),
        source_fabric: yup.object().shape({
            vendor: yup.string().required('Enter source fabric vendor.'),
            model: yup.string(),
            link_speed: yup.number().required('Enter source fabric link speed in Gbps.').nullable(),
            vsan_enabled: yup.boolean().nullable(),
            credentials: yup.boolean().nullable(),
            port_zone: yup.boolean().nullable(),
            switch_report_file: yup.mixed().nullable(),
            fabric_restrictions: yup.string().nullable(),
        }),
        san_config: yup.object().shape({
            cluster: yup.object().shape({
                aix: yup.boolean().nullable(),
                esx: yup.boolean().nullable(),
                hpux: yup.boolean().nullable(),
                linux: yup.boolean().nullable(),
                solaris: yup.boolean().nullable(),
                windows: yup.boolean().nullable(),
            }),
            san_boot: yup.object().shape({
                aix: yup.boolean().nullable(),
                esx: yup.boolean().nullable(),
                hpux: yup.boolean().nullable(),
                linux: yup.boolean().nullable(),
                solaris: yup.boolean().nullable(),
                windows: yup.boolean().nullable(),
            }),
            total_hosts: yup.object().shape({
                aix: yup.number(),
                esx: yup.number(),
                hpux: yup.number(),
                linux: yup.number(),
                solaris: yup.number(),
                windows: yup.number(),
            }),
            average_initiators: yup.object().shape({
                aix: yup.number(),
                esx: yup.number(),
                hpux: yup.number(),
                linux: yup.number(),
                solaris: yup.number(),
                windows: yup.number(),
            }),
            lpar_notes: yup.number().nullable(),
            blade_server_notes: yup.string().nullable(),
            host_type_description: yup.string().nullable()
        }),
        destination: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of destination storage involved.'),
            entries: yup.array().of(
                yup.object().shape({
                    model: yup.string().required('Enter destination storage model.'),
                    credentials: yup.boolean().nullable()
                })
            )
        }),
        network: yup.object().shape({
            local: yup.object().shape({
                firewall_restrictions: yup.boolean().nullable(),
                firewall_rules_modifiable: yup.boolean().nullable(),
                network_security_notes: yup.string().nullable()
            }),
            remote: yup.object().shape({
                remote_access_availability: yup.string().nullable()
            })
        }),
        project_time_frame: yup.object().shape({
            must_start_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter required start date.'),
            must_complete_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter required complete date.'),
            expected_start_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter expected start date.'),
            expected_complete_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter expected complete date.'),
        }),
        site_info: siteInfoSchema
    })

    const schema = yup.object().shape({
        user: yup.object().shape({
            name: yup.string().required('Enter your name.'),
            email: yup.string().required('Enter your email.'),
            phone: yup.string().nullable()
        }),
        source_storage: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of source storage.'),
            total_capacity: yup.number().required('Enter total source storage capacity in TiB.'),
            san_connectivity: yup.string().required('Enter connectivity type.'),
            iscsi: yup.object().nullable().shape({
                switch_report: yup.mixed().required('Add a switch report.'),
                array_performance_stats: yup.mixed().required('Add array performance stats.'),
                host_performance_stats: yup.mixed().required('Add host performance stats.'),
                adaptors: yup.array().of(
                    yup.object().shape({
                        make: yup.string().required('Enter make.'),
                        model: yup.string().required('Enter model.')
                    })
                )
            }),
            entries: yup.array().of(
                yup.object().shape({
                    make: yup.string().required('Enter source storage make.'),
                    model: yup.string().required('Enter source storage model.'),
                    targets: yup.string().required('Enter number of targets.'),
                    credentials: yup.boolean().nullable(),
                    source_storage_report: yup.mixed().nullable()
                })
            )
        }),
        source_fabric: yup.object().shape({
            vendor: yup.string().required('Enter source fabric vendor.'),
            model: yup.string(),
            link_speed: yup.number().required('Enter source fabric link speed in Gbps.').nullable(),
            vsan_enabled: yup.boolean().nullable(),
            credentials: yup.boolean().nullable(),
            port_zone: yup.boolean().nullable(),
            switch_report_file: yup.mixed().nullable(),
            fabric_restrictions: yup.string().nullable(),
        }),
        san_config: yup.object().shape({
            cluster: yup.object().shape({
                aix: yup.boolean().nullable(),
                esx: yup.boolean().nullable(),
                hpux: yup.boolean().nullable(),
                linux: yup.boolean().nullable(),
                solaris: yup.boolean().nullable(),
                windows: yup.boolean().nullable(),
            }),
            san_boot: yup.object().shape({
                aix: yup.boolean().nullable(),
                esx: yup.boolean().nullable(),
                hpux: yup.boolean().nullable(),
                linux: yup.boolean().nullable(),
                solaris: yup.boolean().nullable(),
                windows: yup.boolean().nullable(),
            }),
            total_hosts: yup.object().shape({
                aix: yup.number(),
                esx: yup.number(),
                hpux: yup.number(),
                linux: yup.number(),
                solaris: yup.number(),
                windows: yup.number(),
            }),
            average_initiators: yup.object().shape({
                aix: yup.number(),
                esx: yup.number(),
                hpux: yup.number(),
                linux: yup.number(),
                solaris: yup.number(),
                windows: yup.number(),
            }),
            lpar_notes: yup.number().nullable(),
            blade_server_notes: yup.string().nullable(),
            host_type_description: yup.string().nullable()
        }),
        destination: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of destination storage involved.'),
            entries: yup.array().of(
                yup.object().shape({
                    model: yup.string().required('Enter destination storage model.'),
                    credentials: yup.boolean().nullable()
                })
            )
        }),
        network: yup.object().shape({
            local: yup.object().shape({
                firewall_restrictions: yup.boolean().nullable(),
                firewall_rules_modifiable: yup.boolean().nullable(),
                network_security_notes: yup.string().nullable()
            }),
            remote: yup.object().shape({
                remote_access_availability: yup.string().nullable()
            })
        }),
        project_time_frame: yup.object().shape({
            must_start_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter required start date.'),
            must_complete_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter required complete date.'),
            expected_start_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter expected start date.'),
            expected_complete_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter expected complete date.'),
        }),
        site_info: siteInfoSchema,
        additional_site_info: yup.array().of(additionalSiteSchema),
        misc: yup.object().shape({
            sales_rep: yup.string().required('Enter Name of your Sales Representative.')
        })

    });


    return (<Box width={'100%'} pb={6}>

            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values, actions) => {

                let formattedValues = formatDataForSubmission(values);
                const formattedAdditionalSites = values.additional_site_info.map((s, i) => {
                    return formatDataForSubmission(s)
                })

                const valuesForSubmission: MIGRATION_QUESTIONNAIRE_DATA_TYPE = {
                    user: values.user,
                    additional_site_info: formattedAdditionalSites,
                    misc: values.misc,
                    ...formattedValues
                }

                //console.log(valuesForSubmission)
                await migrationQuestionnaireService.submitQuestionnaire(valuesForSubmission);
                actions.setSubmitting(false);
                actions.resetForm();
                window.scrollTo(0, 0);
                window.location.reload();
            }}>
                {props => {

                    return <Box width={'100%'} pb={6}>
                        <Typography variant={'h4'}>Cirrus Data Migration Questionnaire</Typography>
                        <Typography variant={'subtitle1'}><em>Required for Cirrus Data PS project
                            creation.</em></Typography>
                        <Box pt={2}>
                            <Typography variant={'body1'}>
                                Thank you for selecting Cirrus Data for your data migration.
                                To ensure a smooth, timely and successful engagement, the questionnaire is required
                                for us to move forward. Cirrus Data uses the data to create a project and assign
                                an engineer for planning and execution of your data migration. Please help us in
                                gathering this
                                important information by selecting the appropriate answers for each field. A pop-up help
                                is
                                available for each option to explain the information field and instructions for how best
                                to collect it.
                                <br/><br/>
                                * = Required field.
                            </Typography>
                        </Box>
                        <Box pt={2} pb={6}>
                            <UserInfoSection/>
                        </Box>
                        <ProjectSiteSection values={props.values} initialValues={props.initialValues}
                                            setFieldValue={props.setFieldValue}
                                            migrationQuestionnaireService={migrationQuestionnaireService}/>
                        <Box pb={6}>
                            <AdditionalSitesSection item={props.values} initialValues={props.initialValues}
                                                    setFieldValue={props.setFieldValue}
                                                    migrationQuestionnaireService={migrationQuestionnaireService}/>
                        </Box>
                        <Box pb={6}>
                            <MiscSection/>
                        </Box>
                        <Box pb={6}>
                            <Typography variant={'body1'}>
                                By continuing, the completed answers to this questionnaire will be submitted to CDS for
                                project planning purpose.
                                A copy will automatically be sent to the user completed these answers.
                            </Typography>
                        </Box>
                        <Box className={styles.submitButton}>
                            <Button color={'primary'} variant={'contained'}
                                    disabled={props.isSubmitting}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        const validationErrors = await props.validateForm(props.values);
                                        //console.log(props.values)
                                        if (Object.keys(validationErrors).length > 0) {
                                            migrationQuestionnaireService.dialogService.addAlertDialog('Form Field Error', `Please review the form for errors.`).then(
                                                value => {
                                                    if (value) {
                                                        migrationQuestionnaireService.openAll();
                                                        window.scrollTo(0, 0)
                                                    }
                                                })
                                        }
                                        props.submitForm()
                                    }
                                    }>
                                <Box pl={5} pr={5}>{'Submit'}</Box>
                            </Button>
                        </Box>

                    </Box>
                }}
            </Formik>
        </Box>
    )
})

// ======================
// UserInfoSection
// ======================

interface UserInfoSectionProps {

}

export const UserInfoSection: React.FC<UserInfoSectionProps> = observer((props) => {

    return (
        <Box>
            <Typography variant={'h6'}>Contact Information</Typography>
            <Box pt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Your Name'} name={'user.name'} required/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Your Email'} name={'user.email'} required/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Your Phone'} name={'user.phone'}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
})

// ======================
// ProjectSiteSection
// ======================

interface ProjectSiteSectionProps {
    values: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    setFieldValue: Function
    additionalSite?: boolean;
    additionalSiteIndex?: number;
    migrationQuestionnaireService: MigrationQuestionnaireService;
    handleDelete?: (index: number) => void;
}

export const ProjectSiteSection: React.FC<ProjectSiteSectionProps> = observer((props) => {
    const styles = useMigrationQuestionnaireStyles(props);
    const index = props.additionalSiteIndex + 1 || 0;
    const open = props.migrationQuestionnaireService.openStates[index];
    return <Card variant={'outlined'}>
        <AppBar position={'static'} elevation={0}
                onClick={() => props.migrationQuestionnaireService.toggleOpenState(index)}
                className={styles.collapsibleAppBar}>
            <Toolbar>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} p={1} alignItems={'center'}>
                    <Typography variant={'h6'}>
                        {props.additionalSite ? `Site ${props.additionalSiteIndex + 2}` : 'Primary Site'}
                    </Typography>
                    <SvgIcon htmlColor={'rgb(0,0,0,.6)'}>
                        {open ? <FaAngleUp/> : <FaAngleDown/>}
                    </SvgIcon>
                </Box>
            </Toolbar>
        </AppBar>
        <Collapse in={open}>
            <Box p={2}>
                {props.additionalSite &&
                <Box display={'flex'} pb={2} justifyContent={'flex-end'} className={styles.deleteButton}>
                    <Button variant={'outlined'} color={"inherit"} startIcon={<MdDelete/>}
                            onClick={() => props.handleDelete(props.additionalSiteIndex)}>Delete</Button>
                </Box>
                }


                <Box pb={6}>
                    <DeploymentSiteSection additionalSiteIndex={props.additionalSiteIndex}
                                           additionalSite={props.additionalSite}/>
                </Box>
                <Box pb={6}>
                    <SourceStorageSection item={props.values} initialValues={props.initialValues}
                                          setFieldValue={props.setFieldValue}
                                          additionalSiteIndex={props.additionalSiteIndex}
                                          additionalSite={props.additionalSite}/>
                </Box>
                <Box pb={6}>
                    <SourceFabricSection item={props.values} additionalSiteIndex={props.additionalSiteIndex}
                                         additionalSite={props.additionalSite}/>
                </Box>
                <Box pb={6}>
                    <HostsAndVolumesSection additionalSiteIndex={props.additionalSiteIndex}
                                            additionalSite={props.additionalSite}/>
                </Box>
                <Box pb={6}>
                    <DestinationStorageSection item={props.values} initialValues={props.initialValues}
                                               additionalSiteIndex={props.additionalSiteIndex}
                                               additionalSite={props.additionalSite}/>
                </Box>
                <Box pb={6}>
                    <NetworkSection additionalSiteIndex={props.additionalSiteIndex}
                                    additionalSite={props.additionalSite}/>
                </Box>
                <Box pb={6}>
                    <TimeFrameSection item={props.values} additionalSiteIndex={props.additionalSiteIndex}
                                      additionalSite={props.additionalSite}/>
                </Box>
            </Box>
        </Collapse>


    </Card>
})

// ======================
// SourceStorageSection
// ======================

interface SourceStorageSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE
    setFieldValue: Function
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const SourceStorageSection: React.FC<SourceStorageSectionProps> = observer((props) => {
    const {item, initialValues, setFieldValue, additionalSiteIndex, additionalSite} = props;
    return (<>
            <Typography variant={'h6'}>Source Storage</Typography>
            <Typography variant={'body1'}>(Include virtualized storage models, e.g. VPLEX, SVC, etc.)</Typography>
            <Box pt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormTextField type={'number'} label={'Total Capacity To Be Migrated'}
                                       required
                                       hint={MIGRATION_QUESTIONNAIRE_HINTS.source_storage.total_capacity}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.total_capacity`}
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position={"end"}>
                                                   TiB
                                               </InputAdornment>
                                           )
                                       }}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSelect
                            name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.san_connectivity`}
                            label={'SAN Connectivity'} selectionList={['iSCSI', 'FC']}
                            hint={MIGRATION_QUESTIONNAIRE_HINTS.source_storage.san_connectivity}
                            required/>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={4}>
                <IscsiSection item={item} initialValues={initialValues} setFieldValue={setFieldValue}
                              additionalSite={additionalSite} additionalSiteIndex={additionalSiteIndex}/>
                <SourceStorageCards item={item} initialValues={initialValues} additionalSite={additionalSite}
                                    additionalSiteIndex={additionalSiteIndex}/>
            </Box>
        </>
    )
})

// ======================
// SourceStorageCards
// ======================

interface SourceStorageCardsProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const SourceStorageCards: React.FC<SourceStorageCardsProps> = observer((props) => {
    const {item, initialValues, additionalSite, additionalSiteIndex} = props;

    const {dialogService} = useAppServices();
    return <FieldArray
        name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.entries`}
        render={({push, remove}) => {
            const handleDelete = async (index: number) => {
                const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this Source Storage?', null, false);
                if (confirmed) {
                    remove(index)
                }
            }

            const handleAdd = () => {
                push({
                    make: '',
                    model: '',
                    targets: '',
                    credentials: null,
                    storage_report_file: null
                })
            }
            return (<Grid container spacing={3}>
                    {!additionalSite && item.source_storage.entries.map((entry, index) => {
                        initialValues.source_storage.entries[index] = {
                            make: '',
                            model: '',
                            targets: '',
                            credentials: null,
                            storage_report_file: null
                        }
                        return (<SourceStorageFields handleDelete={handleDelete} index={index} item={item}
                                                     initialValues={initialValues}/>
                        )
                    })}
                    {additionalSite && item.additional_site_info[additionalSiteIndex].source_storage.entries.map((entry, index) => {
                        return (<SourceStorageFields handleDelete={handleDelete} index={index} item={item}
                                                     initialValues={initialValues}
                                                     additionalSiteIndex={additionalSiteIndex}
                                                     additionalSite={additionalSite}/>
                        )
                    })}
                    <Grid item xs={12} md={4}>
                        <Card variant={'outlined'}>
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={630}>
                                <Box textAlign={'center'}>
                                    <Typography variant={'body1'}><b>Add A Source</b></Typography>
                                    <IconButton onClick={handleAdd}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            )
        }}/>
})

// ======================
// SourceStorageFields
// ======================

interface SourceStorageFieldsProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    additionalSite?: boolean;
    additionalSiteIndex?: number;
    index: number;
    handleDelete: (index: number) => void;
}

export const SourceStorageFields: React.FC<SourceStorageFieldsProps> = observer((props) => {
    const {item, initialValues, additionalSite, additionalSiteIndex, index, handleDelete} = props;

    return <Grid item xs={12} md={4} key={index}>
        <Card variant={'outlined'}>
            <Box p={2} height={630}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant={"subtitle2"}>Source
                        Storage {index + 1}</Typography>
                    <IconButton
                        onClick={() => handleDelete(index)}><SvgIcon><IoMdClose/></SvgIcon></IconButton>
                </Box>

                <Box pb={2} pt={2}>
                    <FormTextField label={'Make  '}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].make`}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Model  '}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].model`}
                                   disabled={item.source_storage.entries[index] ? !item.source_storage.entries[index].make : !item.source_storage.entries[index]}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Number Of Target Ports'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].targets`}
                                   type={'number'}
                                   disabled={item.source_storage.entries[index] ? !item.source_storage.entries[index].model : !item.source_storage.entries[index]}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormCheckboxItem label={'Access Credentials Available'}
                                      name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].credentials`}
                    />
                </Box>
                <Box>
                    <QDropzone label={'Attach Storage Report'}
                               dropzoneId={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].storage_report_file`}/>
                </Box>
            </Box>
        </Card>
    </Grid>
})

// ======================
// IscsiSection
// ======================

interface IscsiSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    setFieldValue: Function;
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const IscsiSection: React.FC<IscsiSectionProps> = observer((props) => {
    const {item, initialValues, setFieldValue, additionalSiteIndex, additionalSite} = props;
    const {dialogService} = useAppServices();

    const connectivity = !additionalSite ? item.source_storage.san_connectivity : item.additional_site_info[additionalSiteIndex].source_storage.san_connectivity;

    React.useEffect(() => {
        if (connectivity === 'FC') {
            setFieldValue(`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi`, null)
        }
        if (connectivity === 'iSCSI') {
            setFieldValue(`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi`, {
                switch_report: null,
                host_performance_stats: null,
                array_performance_stats: null,
                adaptors: [{
                    make: '',
                    model: ''
                }]
            })
        }
    }, [connectivity])


    if (connectivity === 'iSCSI') {

        return <Box pb={4}>
            <Box pb={2}>
                <FieldArray
                    name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.adaptors`}
                    render={({push, remove}) => {
                        const handleDelete = async (index: number) => {
                            const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this iSCSI adaptor?', null, false);
                            if (confirmed) {
                                remove(index)
                            }
                        }

                        const handleAdd = () => {
                            push({
                                make: '',
                                model: '',
                            })
                        }
                        return (<Grid container spacing={3}>
                                {!additionalSite && item.source_storage.iscsi?.adaptors.map((entry, index) => {
                                    // initialValues.source_storage.iscsi.adaptors[index] = {
                                    //     make: '',
                                    //     model: '',
                                    // }
                                    return <IscsiFields index={index} handleDelete={handleDelete}/>
                                })}
                                {additionalSite &&
                                item.additional_site_info[additionalSiteIndex].source_storage.iscsi?.adaptors.map((entry, index) => {
                                    return <IscsiFields index={index} handleDelete={handleDelete}
                                                        additionalSiteIndex={additionalSiteIndex}
                                                        additionalSite={additionalSite}/>
                                })
                                }
                                <Grid item xs={12} md={4}>
                                    <Card variant={'outlined'}>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
                                             height={250}>
                                            <Box textAlign={'center'}>
                                                <Typography variant={'body1'}><b>Add An Adaptor</b></Typography>
                                                <IconButton onClick={handleAdd}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        )
                    }}/>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <QDropzone label={'Attach Switch Report(s)*'}
                               dropzoneId={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.switch_report`}/>
                </Grid>
                <Grid item xs={12}>
                    <QDropzone label={'Attach Source Array Performance Stats (Last 3-5 Days)*'}
                               dropzoneId={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.array_performance_stats`}/>
                </Grid>
                <Grid item xs={12}>
                    <QDropzone label={'Attach Host Performance Stats (Last 3-5 Days)*'}
                               dropzoneId={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.host_performance_stats`}/>
                </Grid>
            </Grid>
        </Box>
    } else {
        return null;
    }
})

// ======================
// IscsiFields
// ======================

interface IscsiFieldsProps {

    additionalSite?: boolean;
    additionalSiteIndex?: number;
    index: number;
    handleDelete: (index: number) => void;
}

export const IscsiFields: React.FC<IscsiFieldsProps> = observer((props) => {
    const {additionalSiteIndex, additionalSite, index, handleDelete} = props;
    return <Grid item xs={12} md={4} key={index}>
        <Card variant={'outlined'}>
            <Box p={2} height={250}>
                <Box display={'flex'} justifyContent={'space-between'}
                     alignItems={'center'}>
                    <Typography variant={"subtitle2"}>iSCSI
                        Adaptor {index + 1}</Typography>
                    <IconButton
                        onClick={() => handleDelete(index)}><SvgIcon><IoMdClose/></SvgIcon></IconButton>
                </Box>

                <Box pb={2} pt={2}>
                    <FormTextField label={'Make  '}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.adaptors[${index}].make`}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Model  '}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.adaptors[${index}].model`}
                                   required
                    />
                </Box>
            </Box>
        </Card>
    </Grid>

})

// ======================
// SourceFabricSection
// ======================

interface SourceFabricSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const SourceFabricSection: React.FC<SourceFabricSectionProps> = observer((props) => {
    const {item, additionalSite, additionalSiteIndex} = props;

    const styles = useMigrationQuestionnaireStyles(props);

    return (
        <>
            <Typography variant={'h6'}>FC Switches and Fabrics Information</Typography>
            <Box pt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <FormSelect
                            name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.vendor`}
                            selectionList={['Cisco', 'Brocade']}
                            label={'Vendor  '}
                            hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.vendor}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField
                            name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.model`}
                            label={'Model  '}
                            hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.model}
                            disabled={!item.source_fabric.vendor}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField
                            name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.link_speed`}
                            label={'Link Speed'}
                            type={'number'} required InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    Gbps
                                </InputAdornment>
                            )
                        }}
                            hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.link_speed}
                            disabled={!item.source_fabric.vendor}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormCheckboxItem label={'Virtual Fabric / VSAN Enabled'}
                                          name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.vsan_enabled`}
                                          hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.vsan_enabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormCheckboxItem label={'Port Zone Used'}
                                          name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.port_zone`}
                                          hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.port_zone}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormCheckboxItem label={'Switch Credentials Available'}
                                          name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.credentials`}
                                          hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.credentials}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField label={'Number of Licensed Free Switch Ports Per Fabric'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.fabric_restrictions`}
                                       hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.fabric_restrictions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <QDropzone label={'Attach Switch Report.'}
                                   dropzoneId={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}source_fabric.switch_report_file`}
                                   hint={MIGRATION_QUESTIONNAIRE_HINTS.source_fabric.switch_report_file}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
})

// ======================
// HostsAndVolumesSection
// ======================

interface HostsAndVolumesSectionProps {
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const HostsAndVolumesSection: React.FC<HostsAndVolumesSectionProps> = observer((props) => {
    const {additionalSite, additionalSiteIndex} = props;
    return (
        <>
            <Box pb={2}>
                <Typography variant={'h6'}>Hosts and Volumes to be Migrated</Typography>
            </Box>
            <Grid container spacing={2}>
                <SANConfigCard type={'windows'} additionalSiteIndex={additionalSiteIndex} additionalSite={additionalSite}/>
                <SANConfigCard type={'linux'} additionalSiteIndex={additionalSiteIndex} additionalSite={additionalSite}/>
                <SANConfigCard type={'aix'} additionalSiteIndex={additionalSiteIndex} additionalSite={additionalSite}/>
                <SANConfigCard type={'hpux'} additionalSiteIndex={additionalSiteIndex} additionalSite={additionalSite}/>
                <SANConfigCard type={'esx'} additionalSiteIndex={additionalSiteIndex} additionalSite={additionalSite}/>
                <SANConfigCard type={'solaris'} additionalSiteIndex={additionalSiteIndex} additionalSite={additionalSite}/>
                <Grid item xs={12}>
                    <FormTextField label={'Additional Description on Host OS and Types In-Scope'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.host_type_description`}
                                   hint={MIGRATION_QUESTIONNAIRE_HINTS.san_config.host_type_description}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormTextField label={'Identify Vendor & Model of Blade Servers If In-Scope'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.blade_server_notes`}
                                   hint={MIGRATION_QUESTIONNAIRE_HINTS.san_config.blade_server_notes}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormTextField label={'If LPARs In-Scope, Number of LPARs In-Scope to Migrate'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.lpar_notes`}
                                   hint={MIGRATION_QUESTIONNAIRE_HINTS.san_config.lpar_notes}
                    />
                </Grid>
            </Grid>
        </>
    )
})

// ======================
// SANConfigCard
// ======================

interface SANConfigCardProps {
    type: 'windows' | 'linux' | 'aix' | 'hpux' | 'esx' | 'solaris';
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const SANConfigCard: React.FC<SANConfigCardProps> = observer((props) => {
    const {type, additionalSite, additionalSiteIndex} = props;

    const titles = {
        windows: 'Windows',
        linux: 'Linux',
        aix: 'AIX',
        hpux: 'HP-UX',
        esx: 'ESX',
        solaris: 'Solaris'
    }

    return <Grid item xs={6} md={2}>
        <Card variant={'outlined'}>
            <Box p={2}>
                <Box pb={3}>
                    <Typography variant={'subtitle2'}>{titles[type]}</Typography>
                </Box>
                <Box pb={3}>
                    <FormTextField label={'Number of Hosts'} type={'number'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.total_hosts.${type}`}/>
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Avg. Initiators'} type={'number'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.average_initiators.${type}`}/>
                </Box>
                <FormCheckboxItem label={'Cluster'}
                                  name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.cluster.${type}`}/>
                <FormCheckboxItem label={'Boot from SAN'}
                                  name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}san_config.san_boot.${type}`}/>
            </Box>
        </Card>
    </Grid>
})


// ======================
// DestinationStorageSection
// ======================

interface DestinationStorageSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const DestinationStorageSection: React.FC<DestinationStorageSectionProps> = observer((props) => {
    const {item, initialValues, additionalSite, additionalSiteIndex} = props;
    return (
        <>
            <Box pb={2}>
                <Typography variant={'h6'}>Destination Storage</Typography>
            </Box>
            <Grid container spacing={2}>
                <DestinationStorageCardsSection item={item} initialValues={initialValues}
                                                additionalSite={additionalSite}
                                                additionalSiteIndex={additionalSiteIndex}/>
            </Grid>
        </>
    )
})

// ======================
// DestinationStorageCardsSection
// ======================

interface DestinationStorageCardsSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE,
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE,
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const DestinationStorageCardsSection: React.FC<DestinationStorageCardsSectionProps> = observer((props) => {

    const {item, initialValues, additionalSite, additionalSiteIndex} = props;

    const {dialogService} = useAppServices();

    return <FieldArray name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}destination.entries`} render={({push, remove}) => {
        const handleDelete = async (index: number) => {
            const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this Destination Storage?', null, false);
            if (confirmed) {
                remove(index)
            }
        }

        const handleAdd = () => {
            push({
                model: '',
                credentials: null,
            })
        }
        return (<>
            {!additionalSite && item.destination.entries.map((entry, index) => {
                initialValues.destination.entries[index] = {
                    model: '',
                    credentials: null
                }
                return <DestinationStorageFields item={item} handleDelete={handleDelete} index={index}/>
            })}
            {additionalSite && item.additional_site_info[additionalSiteIndex].destination.entries.map((entry, index) => {
                return <DestinationStorageFields item={item} handleDelete={handleDelete} index={index} additionalSite={additionalSite} additionalSiteIndex={additionalSiteIndex}/>
            })}
            <Grid item xs={12} md={4}>
                <Card variant={'outlined'}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={250}>
                        <Box textAlign={'center'}>
                            <Typography variant={'body1'}><b>Add A Destination</b></Typography>
                            <IconButton onClick={handleAdd}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                        </Box>
                    </Box>
                </Card>
            </Grid>

        </>)
    }
    }
    />
})

// ======================
// DestinationStorageFields
// ======================

interface DestinationStorageFieldsProps {
    handleDelete: (index: number) => void;
    index: number;
    additionalSite?: boolean,
    additionalSiteIndex?: number,
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE
}

export const DestinationStorageFields: React.FC<DestinationStorageFieldsProps> = observer((props) => {
    const {handleDelete, index, item, additionalSiteIndex, additionalSite} = props;
    return <Grid item xs={12} md={4}>
        <Card variant={'outlined'}>
            <Box p={2} height={250}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={2}>
                    <Typography variant={"subtitle2"}>Destination
                        Storage {index + 1}</Typography>
                    <IconButton
                        onClick={() => handleDelete(index)}><SvgIcon><IoMdClose/></SvgIcon></IconButton>
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Model  '}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}destination.entries[${index}].model`}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormCheckboxItem label={'Access Credentials Available'}
                                      name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}destination.entries[${index}].credentials`}

                    />
                </Box>
            </Box>
        </Card>
    </Grid>
})


// ======================
// NetworkSection
// ======================

interface NetworkSectionProps {
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const NetworkSection: React.FC<NetworkSectionProps> = observer((props) => {
    const {additionalSite, additionalSiteIndex} = props;
    return (
        <>
            <Box>
                <Typography variant={'h6'}>Network Access</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormCheckboxItem label={'Local Area Network Has Firewall Restriction'}
                                      name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}network.local.firewall_restrictions`}
                                      hint={MIGRATION_QUESTIONNAIRE_HINTS.network.local.firewall_restrictions}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormCheckboxItem label={'Firewall Rules Modifiable'}
                                      name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}network.local.firewall_rules_modifiable`}
                                      hint={MIGRATION_QUESTIONNAIRE_HINTS.network.local.firewall_rules_modifiable}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField label={'Additional Descriptions on Security Restrictions'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}network.local.network_security_notes`}
                                   hint={MIGRATION_QUESTIONNAIRE_HINTS.network.local.network_security_notes}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField label={'Secure Remote Access Availability'}
                                   name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}network.remote.remote_access_availability`}
                                   hint={MIGRATION_QUESTIONNAIRE_HINTS.network.remote}
                    />
                </Grid>
            </Grid>
        </>
    )
})

// ======================
// TimeFrameSection
// ======================

interface TimeFrameSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE;
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const TimeFrameSection: React.FC<TimeFrameSectionProps> = observer((props) => {
    const {item, additionalSite, additionalSiteIndex} = props;
    const convertDate = (date: Date | string | number) => {
        if (typeof date !== 'object') {
            return date;
        } else {
            return format(date, 'MM/dd/yyyy')
        }
    }
    return (
        <>
            <Box pb={2}>
                <Typography variant={'h6'}>Migration Time Frame</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Required Start Date'}
                                    name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}project_time_frame.must_start_date`}
                                    hint={MIGRATION_QUESTIONNAIRE_HINTS.project_time_frame.must_start_date}
                                    required
                                    disablePast
                                    convertDateToValue={convertDate}

                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Required End Date'}
                                    name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}project_time_frame.must_complete_date`}
                                    hint={MIGRATION_QUESTIONNAIRE_HINTS.project_time_frame.must_complete_date}
                                    disablePast
                                    required
                                    convertDateToValue={convertDate}

                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Expected Start Date'}
                                    name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}project_time_frame.expected_start_date`}
                                    hint={MIGRATION_QUESTIONNAIRE_HINTS.project_time_frame.expected_start_date}
                                    disablePast
                                    required
                                    convertDateToValue={convertDate}

                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Expected End Date'}
                                    name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}project_time_frame.expected_complete_date`}
                                    hint={MIGRATION_QUESTIONNAIRE_HINTS.project_time_frame.expected_complete_date}
                                    disablePast
                                    required
                                    convertDateToValue={convertDate}
                    />
                </Grid>
            </Grid>
        </>
    )
})

// ======================
// DeploymentSiteSection
// ======================

interface DeploymentSiteSectionProps {
    additionalSite?: boolean;
    additionalSiteIndex?: number;
}

export const DeploymentSiteSection: React.FC<DeploymentSiteSectionProps> = observer((props) => {
    const {additionalSite, additionalSiteIndex} = props;

    return (
        <>
            <Box pb={2}>
                <Typography variant={'h6'}>Deployment Site Information</Typography>
            </Box>

            {/*                    <Box pb={2}>
                        <Typography variant={'subtitle2'}>Description</Typography>
                        <br/>
                        <FormTextField label={'Description'} name={`site_info.description`} multiline/>
                    </Box>*/}
            <Box pb={2}>
                <Typography variant={'subtitle2'}>Customer Contact Information</Typography>
            </Box>
            <Box pb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Company'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.customer.company`}
                                       required/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Name'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.customer.name`}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Phone'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.customer.phone`}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Email'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.customer.email`}/>
                    </Grid>
                </Grid>
            </Box>

            <Box pb={2}>
                <Typography variant={'subtitle2'}>On-Site Contact Information</Typography>
            </Box>
            <Box pb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Name'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.onsite_contact.name`}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Phone'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.onsite_contact.phone`}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Email'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.onsite_contact.email`}/>
                    </Grid>
                </Grid>
            </Box>
            <Box pb={2}>
                <Typography variant={'subtitle2'}>Location Information</Typography>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormTextField label={'Address'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.address`}
                                       hint={MIGRATION_QUESTIONNAIRE_HINTS.site_info.location.address}
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <FormTextField label={'Required Security Credentials'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.security_requirements`}
                                       hint={MIGRATION_QUESTIONNAIRE_HINTS.site_info.location.security_requirements}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormCheckboxItem label={'Escort Required'}
                                          name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.escort_required`}
                                          hint={MIGRATION_QUESTIONNAIRE_HINTS.site_info.location.escort_required}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField label={'Installation Permission'}
                                       name={`${additionalSite ? 'additional_site_info' : ''}${additionalSite ? `[${additionalSiteIndex}]` : ''}site_info.location.source.installation_permission`}
                                       hint={MIGRATION_QUESTIONNAIRE_HINTS.site_info.location.installation_permission}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
})

// ======================
// AdditionalSitesSection
// ======================

interface AdditionalSitesSectionProps {
    item: MIGRATION_QUESTIONNAIRE_DATA_TYPE
    initialValues: MIGRATION_QUESTIONNAIRE_DATA_TYPE
    setFieldValue: Function
    migrationQuestionnaireService: MigrationQuestionnaireService
}

export const AdditionalSitesSection: React.FC<AdditionalSitesSectionProps> = observer((props) => {
    const {dialogService} = useAppServices();
    const {item, initialValues, setFieldValue, migrationQuestionnaireService} = props;

    return <Box>
        <FieldArray name={'additional_site_info'} render={({push, remove}) => {
            const handleDelete = async (index: number) => {
                const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this Deployment Site?', null, false);
                if (confirmed) {
                    remove(index);
                    migrationQuestionnaireService.deleteOpenState(index + 1);

                }
            }

            const handleAdd = (index: number) => {
                migrationQuestionnaireService.addOpenState(index);
                migrationQuestionnaireService.openStates.forEach((s, i) => {
                    if (i !== index) {
                        migrationQuestionnaireService.setClosed(i)
                    }
                })


                push({
                    source_storage: {
                        total_storage_involved: 1,
                        total_capacity: 0,
                        san_connectivity: '',
                        entries: [{
                            make: '',
                            model: '',
                            targets: '',
                            credentials: null,
                            storage_report_file: null
                        }],
                        iscsi: {
                            switch_report: null,
                            host_performance_stats: null,
                            array_performance_stats: null,
                            adaptors: []
                        }
                    },
                    source_fabric: {
                        model: '',
                        vendor: '',
                        port_zone: null,
                        link_speed: null,
                        credentials: null,
                        vsan_enabled: null,
                        fabric_restrictions: null,
                        switch_report_file: null
                    },
                    san_config: {
                        cluster: {
                            aix: null,
                            esx: null,
                            hpux: null,
                            linux: null,
                            solaris: null,
                            windows: null,
                        },
                        host_type_description: null,
                        total_hosts: {
                            aix: 0,
                            esx: 0,
                            hpux: 0,
                            linux: 0,
                            solaris: 0,
                            windows: 0,
                        },
                        lpar_notes: 0,
                        san_boot: {
                            aix: null,
                            esx: null,
                            hpux: null,
                            linux: null,
                            solaris: null,
                            windows: null,
                        },
                        average_initiators: {
                            aix: 0,
                            esx: 0,
                            hpux: 0,
                            linux: 0,
                            solaris: 0,
                            windows: 0,
                        },
                        blade_server_notes: null
                    },
                    destination: {
                        total_storage_involved: 1,
                        entries: [{
                            model: '',
                            credentials: null
                        }]
                    },
                    network: {
                        local: {
                            firewall_restrictions: null,
                            firewall_rules_modifiable: null,
                            network_security_notes: null
                        },
                        remote: {
                            remote_access_availability: null
                        }
                    },
                    project_time_frame: {
                        must_start_date: format(new Date(), 'MM/dd/yyyy'),
                        must_complete_date: format(new Date(), 'MM/dd/yyyy'),
                        expected_start_date: format(new Date(), 'MM/dd/yyyy'),
                        expected_complete_date: format(new Date(), 'MM/dd/yyyy')
                    },
                    site_info: {
                        description: null,
                        customer: {
                            name: null,
                            email: null,
                            phone: null,
                            company: ''
                        },
                        location: {
                            source: {
                                address: null,
                                onsite_contact: {
                                    name: null,
                                    email: null,
                                    phone: null
                                },
                                escort_required: null,
                                security_requirements: null,
                                installation_permission: null
                            },
                        }
                    }
                })
                window.scrollTo(0, 0)
            }
            return (<Box>
                    <Box pb={2}>
                        {item.additional_site_info.map((entry, index) => {
                            return (
                                <ProjectSiteSection values={item}
                                                    initialValues={initialValues}
                                                    setFieldValue={setFieldValue}
                                                    migrationQuestionnaireService={migrationQuestionnaireService}
                                                    additionalSite={true}
                                                    additionalSiteIndex={index}
                                                    handleDelete={handleDelete}
                                />

                            )
                        })}
                    </Box>
                    <Box>
                        <Button fullWidth onClick={() => handleAdd(migrationQuestionnaireService.openStatesLength)} color={'primary'} variant={'contained'} size={'large'} startIcon={<SvgIcon><MdAdd/></SvgIcon>}>Add Deployment Site</Button>
                    </Box>
                    {/*<Card variant={'outlined'}>
                        <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Box textAlign={'center'}>
                                <Typography variant={'h6'}><b>Add Deployment Site</b></Typography>
                                <IconButton
                                    onClick={() => handleAdd(migrationQuestionnaireService.openStatesLength)}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                            </Box>
                        </Box>
                    </Card>*/}

                </Box>

            )
        }}/>


    </Box>
})

// ======================
// MiscSection
// ======================

interface MiscSectionProps {
}

export const MiscSection: React.FC<MiscSectionProps> = observer((props) => {

    return (
        <>
            <Box pb={2}>
                <Typography variant={'h6'}>Misc. Information</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField label={'Cirrus Data Sales Representative (First Last)'} name={'misc.sales_rep'} required/>
                </Grid>
            </Grid>
        </>
    )
})

const formatDataForSubmission = (values: PROJECT_SITE_TYPE) => {
    values.source_storage.entries.map(entry => {
        if (!entry.credentials) {
            entry.credentials = null;
        }
    })

    values.destination.entries.map(entry => {
        if (!entry.credentials) {
            entry.credentials = null;
        }
    })

    if (values.source_storage.iscsi) {
        values.source_storage.iscsi.array_performance_stats = values.source_storage.iscsi.array_performance_stats.length ? values.source_storage.iscsi.array_performance_stats.map(v => ({
            uuid: v.uuid,
            fileName: v.name,
            fileSize: v.size,
            fileType: v.type
        })) : [{
            uuid: values.source_storage.iscsi.array_performance_stats.uuid,
            fileName: values.source_storage.iscsi.array_performance_stats.name,
            fileSize: values.source_storage.iscsi.array_performance_stats.size,
            fileType: values.source_storage.iscsi.array_performance_stats.type
        }]
        values.source_storage.iscsi.host_performance_stats = values.source_storage.iscsi.host_performance_stats.length ? values.source_storage.iscsi.host_performance_stats.map(v => ({
            uuid: v.uuid,
            fileName: v.name,
            fileSize: v.size,
            fileType: v.type
        })) : [{
            uuid: values.source_storage.iscsi.host_performance_stats.uuid,
            fileName: values.source_storage.iscsi.host_performance_stats.name,
            fileSize: values.source_storage.iscsi.host_performance_stats.size,
            fileType: values.source_storage.iscsi.host_performance_stats.type
        }]
        values.source_storage.iscsi.switch_report = values.source_storage.iscsi.switch_report.length ? values.source_storage.iscsi.switch_report.map(v => ({
            uuid: v.uuid,
            fileName: v.name,
            fileSize: v.size,
            fileType: v.type
        })) : [{
            uuid: values.source_storage.iscsi.switch_report.uuid,
            fileName: values.source_storage.iscsi.switch_report.name,
            fileSize: values.source_storage.iscsi.switch_report.size,
            fileType: values.source_storage.iscsi.switch_report.type
        }]
    }

    values.source_storage.total_storage_involved = values.source_storage.entries.length;
    values.destination.total_storage_involved = values.destination.entries.length;

    if (values.source_fabric.switch_report_file) {
        if (values.source_fabric.switch_report_file.length > 1) {
            values.source_fabric.switch_report_file = values.source_fabric.switch_report_file.map(value => {
                return {
                    uuid: value.uuid,
                    fileName: value.name,
                    fileSize: value.size,
                    fileType: value.type
                }
            })
        } else {
            values.source_fabric.switch_report_file = {
                uuid: values.source_fabric.switch_report_file.uuid,
                fileName: values.source_fabric.switch_report_file.name,
                fileSize: values.source_fabric.switch_report_file.size,
                fileType: values.source_fabric.switch_report_file.type
            }
        }
    }

    values.source_storage.entries.map(entry => {
        if (entry.storage_report_file) {
            if (entry.storage_report_file.length > 1) {
                entry.storage_report_file = entry.storage_report_file.map(entry => {
                    return {
                        uuid: entry.uuid,
                        fileName: entry.name,
                        fileSize: entry.size,
                        fileType: entry.type
                    }
                })
            } else {
                entry.storage_report_file = {
                    uuid: entry.storage_report_file.uuid,
                    fileName: entry.storage_report_file.name,
                    fileSize: entry.storage_report_file.size,
                    fileType: entry.storage_report_file.type
                }
            }

        }
    })

    return values;
}









