/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: MainLayout
 */
import * as React from 'react';
import {
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Typography, SvgIcon, Tooltip, Divider, Button, useScrollTrigger, Slide
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import Container from "@material-ui/core/Container";
import grey from '@material-ui/core/colors/grey';
import {APP_ROUTES} from "../app/AppRoutes";
import {Link, useRouteMatch} from "react-router-dom";
import Logo from '../../static/images/logo300.png';
import CirrusIcon from '../../static/images/product_logo_icon.png'



const DESKTOP_BREAKPOINT = 'md'; // beyond this consider desktop
const SIDE_BAR_WIDTH = 0;
export const useIsDesktop = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(DESKTOP_BREAKPOINT));
};

interface MainFrameLayoutProps {
}

const useMainFrameStyles = makeStyles((t: Theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
    },
    main: {
        height: `100%`,
        paddingTop: 120,
        paddingLeft: t.spacing(10),
        paddingRight: t.spacing(10)
        //paddingTop: t.spacing(TOP_BAR_HEIGHT),
    },
    mobileMain: {
        height: `100%`,
        paddingTop: 110,
        paddingLeft: t.spacing(2),
        paddingRight: t.spacing(2)
        //paddingTop: t.spacing(TOP_BAR_HEIGHT),
    },
    toolBar: {
        display: 'flex',
        paddingTop: t.spacing(1),
        paddingBottom: t.spacing(1)
    },
    menuButton: {
        marginRight: t.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
    },

    homeButton: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center'
    },

    homeText: {
        paddingLeft: t.spacing(1)
    },
    iconButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    link: {
        color: grey['100'],
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center'

    },
    activeLink: {
        color: t.palette.secondary.light,
        textDecoration: 'none'
    },
    userPopover: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    menuItems: {
        display: 'flex',
        justifyContent: 'center'
    },
    logo      : {
        height: t.spacing(3),
    },
}));

export const MainFrameLayout: React.FC<MainFrameLayoutProps> = observer(({...p}) => {
    const styles = useMainFrameStyles(p);
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const containerClassName = isDesktop ? styles.main : styles.mobileMain

    return (
        <Box className={styles.root} pl={isDesktop ? SIDE_BAR_WIDTH : 0}>
            {isDesktop && <DesktopMenuBar/>}
            {!isDesktop && <MobileMenuBar/>}
            <Container maxWidth={'lg'} className={containerClassName}>
                {p.children}
            </Container>
        </Box>
    )
});

// ======================
// HideOnScroll
// ======================

interface HideOnScrollProps{
}

export const HideOnScroll: React.FC<HideOnScrollProps> = observer((props)=>{
    const trigger = useScrollTrigger({target: window})
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {props.children}
        </Slide>
    )
})

// ======================
// DesktopMenuBar
// ======================

interface MobileMenuBarProps {
}

export const MobileMenuBar: React.FC<MobileMenuBarProps> = observer((props) => {
    const styles = useMainFrameStyles(props);

    return (<HideOnScroll>
            <AppBar elevation={0}>
                <Toolbar className={styles.toolBar}>
                    <Box className={styles.homeButton}>
                        <Link to={APP_ROUTES.MIGRATION} className={styles.link}>
                            <img src={CirrusIcon} className={styles.logo}/>
                            <Typography variant="h6" className={styles.homeText}>
                                Cirrus Data Project Planner
                            </Typography>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        </HideOnScroll>

    )
})

// ======================
// MobileMenuBar
// ======================

interface DesktopMenuBarProps {
}

export const DesktopMenuBar: React.FC<DesktopMenuBarProps> = observer((props) => {
    const styles = useMainFrameStyles(props);

    return (<HideOnScroll>
            <AppBar elevation={0}>
                <Toolbar className={styles.toolBar}>
                    <Box className={styles.homeButton}>
                        <Link to={APP_ROUTES.MIGRATION} className={styles.link}>
                            <img src={CirrusIcon} className={styles.logo}/>
                            <Typography variant="h6" className={styles.homeText}>
                                Cirrus Data Project Planner
                            </Typography>
                        </Link>

                    </Box>
                    <Box>
                        <img src={Logo} className={styles.logo}/>
                    </Box>

                </Toolbar>
            </AppBar>
        </HideOnScroll>

    )
})

