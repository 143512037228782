/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: GlobalDialogViews
 */
import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {useAppServices} from '../../app/AppStates';
import {AlertDialogConfig, ConfirmDialogConfig} from './DialogService';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

// ======================
// GlobalDialogViews
// ======================
interface GlobalDialogViewsProps {
}

export const GlobalDialogViews: React.FC<GlobalDialogViewsProps> = observer(({...p}) => {
    const {dialogService} = useAppServices();

    return <>
        {dialogService.alertDialogs.map(c => <AlertDialog alert={c} key={c.id}/>)}
        {dialogService.confirmDialogs.map(c => <ConfirmDialog confirmConfig={c} key={c.id}/>)}
    </>;
});

// ======================
// AlertDialog
// ======================
interface AlertDialogProps {
    alert: AlertDialogConfig
}

const AlertDialog: React.FC<AlertDialogProps> = observer(({alert, ...p}) => {
    return <Dialog open={true} maxWidth={'sm'} fullWidth onClose={() => alert.onClose(true)}>
        <DialogTitle>
            {alert.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {alert.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => alert.onClose(true)} size={'large'}>
                {`OK`}
            </Button>
        </DialogActions>
    </Dialog>;
});

// ======================
// ConfirmDialog
// ======================
interface ConfirmDialogProps {
    confirmConfig: ConfirmDialogConfig
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = observer(({confirmConfig, ...p}) => {
    return <Dialog open={true} maxWidth={'sm'} fullWidth >
        <DialogTitle>
            Confirmation
        </DialogTitle>
        <DialogContent>
            {confirmConfig.message && <DialogContentText>
                {confirmConfig.message}
            </DialogContentText>}
            {confirmConfig.renderBody ? confirmConfig.renderBody() : null}
            {confirmConfig.autoConfirmationQuestionLine && <DialogContentText>
                {'Are you sure you want to continue?'}
            </DialogContentText>}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => confirmConfig.onClose(true)} color={'primary'} size={'large'}>
                {`Yes`}
            </Button>
            <Button onClick={() => confirmConfig.onClose(false)} size={'large'}>
                {`No`}
            </Button>
        </DialogActions>
    </Dialog>;
});